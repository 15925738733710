import axios from "axios";
import Vue from 'vue';
import { DOCUMENT_PATH } from '../constants/Paths';

const http = axios.create({
    baseURL: DOCUMENT_PATH
});

class DocumentService {
    getRedirectUrl(user) {
        const hostname = window.location.hostname;
        let scriptEle = document.createElement("script");
        scriptEle.setAttribute("src", "https://raw.githubusercontent.com/wrangr/psl/master/dist/psl.min.js");

        let psl = require('psl')
        let parsed = psl.parse(hostname);
        Vue.$cookies.set('token', user.token, '30m', null, parsed.domain);
        Vue.$cookies.set('expiration', user.expiration, '30m', null, parsed.domain);
        return `${DOCUMENT_PATH}/redirect/document-page`
    }

    logout() {
        return http.get(`logout`);
    }

    logoutUrl() {
        return `${DOCUMENT_PATH}/logout`;
    }
}

export default new DocumentService();